import React, { Component } from "react";
import { Link } from "react-router-dom";

function MenuItem({ image, name, description,linkTo, price }) {
  return (
    <div className="menuItem">
      
      <a href={linkTo}>
        <div style={{ backgroundImage: `url(${image})` }}> </div>
        <h1> {name} </h1>
        <p> {description} </p>
      </a> 
      {/* <p> ${price} </p> */}
    </div>
  );
}

export default MenuItem;
