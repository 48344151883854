import React, { useRef } from "react";
import QR from "../assets/qrCode.png";
import "../styles/Contact.css";
// import { Wrapper, Map, Marker } from "@googlemaps/react-wrapper";
import emailjs from '@emailjs/browser';
import Button from '@mui/material/Button';
import GoogleMap from "../components/gMap";

function Contact() {



  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_sq8xu2h', 'template_92cvxtd', form.current, '2KFI31G5jr5e2CLS1')
      .then((result) => {
          console.log(result.text);
          console.log("message sent");
      }, (error) => {
          console.log(error.text);
      });
      form.current.reset();
  };

  return (
    <div className="contact" id="contact"
    >
      <div className="leftSide">
        {/* <GoogleMap></GoogleMap> */}
        
        <p>*rainbow gradiant for text in contact page</p>
        <p> Wanna Join Our Community? </p>  
        <p> Fill Out Our Contact Form: </p>
          <li>For A Website</li>
          <li>Hosting Plan</li>
          <li>Or Inquiries</li> 
        
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
        <p> Redding, CA 96001</p>
        <p> Email:  jaysonsewell002@gmail.com</p>
        
        {/* <Button variant="contained"> Donation </Button> */}
      {/* <h3> QR Code Donations Go to the Redding Bitcoin Center </h3> */}

      </div>
      <div className="rightSide">
        <h1> Contact Us</h1>

        <form ref={form} onSubmit={sendEmail}>
          <label htmlFor="name">Full Name</label>
          <input name="name" placeholder="Enter full name..." type="text" />
          <label htmlFor="email">Email</label>
          <input name="email" placeholder="Enter email..." type="email" />
          <label htmlFor="message">Message</label>
          <textarea
            rows="6"
            placeholder="Enter message..."
            name="message"
            required
          ></textarea>
          <button type="submit" value="send message"> Send Message</button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
