import React, { Component } from "react";
import "../styles/Home.css";
import img5 from '../assets/501main.jpg';

function Home() {

  
  return (

    <div className="home" id="home" 
    // style={{ backgroundImage: `url(${img5})`
  // }}
    >

      <div className="headerContainer">
        
        {/* <p> *Your New Website Design 1.0 </p>
        <i>
          <p>   *We Design And Host Your Website For Half The Price. </p>
          </i>
        <p> Your Headline</p>
        <p>  Happy Hour Times </p>
        <p>  Hours Of Operation </p> */}
      </div>
        <div>
      </div>

    </div>
  );
}


export default Home;
