import "./App.css";
import moon from './assets/sundial.jpg';
import land from './assets/fishphoto.jpg';
import cat from './assets/fabricBackground.jpg';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Menu from "./pages/Menu";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Banner from "./components/Banner";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import Podcast from "./pages/Podcast";
import Navbar2 from "./components/Navbar2";
import Community from "./pages/Community";
import AboutWelcome from "./pages/AboutWelcome";
// import Parallax1 from "./components/Parallax1";
// import Parallax2 from "./components/2Parallax";

import MultiplePizzas from "./assets/pizza.jpeg";

function App() {
  return (
    <div className="App">
      <Router>

        <Route exact path="/">
        <Parallax pages={3} 
        style={{backgroundColor: 'black'}}
          // zIndex={20}
        >
          <ParallaxLayer
            // className="Cat"
            speed={1.5}
            factor={3}
            offset={0}
          >
            <AboutWelcome />

          </ParallaxLayer>
          <ParallaxLayer
  className="Cat"
  speed={1.5}
  factor={2}
  offset={.999}
  // sticky={{ 
  //   // start: 0.9
    // start: 1.5
  //   , end: 2.5 
  // }}
  >
    <div>
            <p>second</p>
            <About/>
    </div>
            {/* <img src={cat} /> */}
          </ParallaxLayer>
        <ParallaxLayer
          className="Moon"
          offset={0}
          speed={1}
          factor={2}
          style={{
            zIndex: '-5',
            backgroundImage: `url(${moon})`,
            backgroundSize: 'cover',
          }}
          >
          <div>
          {/* <Navbar2 /> */}
            {/* <Link to="/about">new tab</Link>
            <p>truely first</p> */}

          </div>
          </ParallaxLayer>

            <ParallaxLayer
              className="AboutFooter"
              speed={2}
              factor={2}
              offset={2}
            >
            {/* <AboutWelcome></AboutWelcome> */}
            <Contact></Contact>
            <Footer></Footer>
            </ParallaxLayer>
            <ParallaxLayer
              className="Land"
              offset={1.5}
              speed={.5}
              factor={2}
              style={{
                width: '100%',
                hieght: '100%',
                zIndex: '-5',
                backgroundImage: `url(${land})`,
                backgroundSize: 'cover',
              }}
              >
            <div>
            <p>first</p>
                {/* <Contact/> */}
                {/* <Footer /> */}
            </div>
              {/* <Contact></Contact> */}
            {/* <Footer></Footer> */}
            </ParallaxLayer>

        </Parallax>
      </Route>
      
      <Route exact path="/about">

      
      
            <Home />
      {/* <Parallax2 /> */}
      
      <Menu />
      <Contact />
      </Route>
      
      </Router>
    </div>
  );
}

export default App;
