import React, { useState } from 'react';
import Data from "../helpers/Slider2";
import ImageGallery from 'react-image-gallery';

function Slider2() { 

  // const [x,setx] = useState(true);
  // const xid = document.getElementById("slide");

  // .addEventListener('click', () => {
  //   console.log("kjwoen");
  // })
  return (
    <div 
    // className="App"
    >
      <div style={{ textAlign: "center" }}>
        <h2>See What We Do</h2>
        <p>*Easy to use, responsive and customizable. Eye catching and never boring.</p>
        <p>*Mobile and desktop friendly - Test it out</p>
        <p>  *Images Of Happy Faces, Dancing, Drinks, ... </p>
        <div style={{
            // width: "100%",
            // height: "900px",
        //   padding: "0 20px"
        }}>

                <ImageGallery
      
                items={Data}
                autoPlay={true}
                // showBullets={true}
                // slideDuration={50}

                >
                </ImageGallery>
        </div>
      </div>
    </div>
  );
}

export default Slider2;