import React from "react";
import "../styles/AboutWelcome.css";
import {HashLink as Link} from 'react-router-hash-link';
// import Slider from "../components/ImageCarousel";
// import SimpleImageSlider from "react-simple-image-slider/dist/ImageSlider";
import Slider2 from "../components/Silder2";

function AboutWelcome() {
  return (
    <div className="aboutWelcome" id="aboutWelcome">
      <div className="aboutWelcomeNav">
        <Link to="/about"><h3>new tab</h3></Link>
        <Link><h3>truely first</h3></Link>
      </div>
    {/* <div className="aboutWelcomeLeft"> */}
    {/* <Slider></Slider> */}
    {/* </div> */}
    {/* <div className="aboutWelcomeRight"> */}
    <h1>Welcome</h1>
    <p> About and History "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
          <h1> ? </h1>
          <p>sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo</p>
          {/* <Slider2 /> */}
    </div>
  // </div> */}
    );
  }

export default AboutWelcome;
